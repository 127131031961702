.order-box { min-height: 60px; padding:0 100px 0 100px; margin: 20px; background: white; overflow: hidden; }
.form-item { min-width: 300px; float: left; min-height: 60px; }
.form-item-block { height: 40px; line-height: 64px; }
.portrait { max-width: 100px; max-height: 100px; }
.layui-form-label { width: 104px; float: left; text-align: right; margin-right: 6px; }
.layui-form { height: auto; overflow: hidden; }
.imgdiv { float: left; }
.close-btn { cursor: pointer; }
.img-item-upload { display: none; }
.order-item span { display: inline-block; }
.attr-box { margin-left: 50px; }
.layui-form-label { line-height: 48px; }
.layui-input-inline { display: inline-block; vertical-align: middle; line-height: 48px; }
.order-item textarea {
    display: inline-block;
}
.showDetailBtn {
    margin-left: 10px;
    margin-top: -14px;
}
.order-block {
    width: 100%;
    height: auto;
    overflow: hidden;
}
.order-box1 {
    min-height: 60px; padding:0 100px 0 100px; margin: 20px; overflow: hidden;
}
.layui-input-block {
    height: auto;
    overflow: hidden;
    float: left;
}