    .tip {
        position: absolute;
        width: 200px;
        height: 80px;
        background: #3299e6;
        border: 1px solid #fff;
        margin-top: -100px;
        text-align: left;
        padding: 6px;
        color: white;
        line-height: 28px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .childLine {
        display: inline-block;
    }
    .children {
        display: inline-block;
    }
    .layNoMargin {
        margin: 0 !important;
    }
