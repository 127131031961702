.dib {
    display: inline-block;
    font-size: 12px;
    vertical-align: top;
    word-spacing: normal;
    line-height: inherit;
    cursor: pointer;
    width: 100px;
    margin-bottom: 10px;
    margin-right: 20px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 1px solid #80808059;
    padding: 6px 0;
}
.dib:hover {
    background: #80808033;
}
.dibSelected {
    background: #80808033;
}

.item_content ul { list-style: none; }
.item_content ul li { width: 140px; height: 100px; float: left; margin: 10px }
.item_content { width: 100%; height: auto; border: 1px solid #ccc; float: left; min-height: 160px; }
.item { width: 140px; height: 100px; line-height: 100px; text-align: center; cursor: pointer; background: #ccc; }
