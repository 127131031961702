.search-switch {
    cursor: pointer;
    width: 20px;
    height: 20px;
    overflow: hidden;
    z-index: 999;
    position: absolute;
}
.search-switch:hover {
    border: 1px solid orange;
}
.header-off {
    height: 20px !important;
    width: 20px;
    overflow: hidden;
    padding-top: 6px !important;
    padding-left: 8px;
    padding-bottom: 0;
}

.layui-form-label {
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}

