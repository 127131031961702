.cover {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(220,162,216,0.6);
    left: 0;
    top: 0;
    text-align: center;
    font-size: 30px;
    display: none;
    padding-top: 100px;
}
.fileTip {
    text-align: center;
    font-size: 30px;
    color: #999;
}