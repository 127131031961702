.order-box { min-height: 60px; padding:0 100px 0 100px; overflow: hidden; }
.form-item { min-width: 300px; float: left; line-height: 36px; white-space: nowrap; }
.form-item-block { height: 40px; line-height: 40px; }
.portrait { max-width: 100px; max-height: 100px; }
.layui-form-label { float: left; display: block; padding: 9px 15px; width: 100px; font-weight: 400; line-height: 20px; text-align: right; position: relative; }
.goods-img { max-width: 200px;max-height: 200px; }
.layui-form { padding-top: 20px; }
.info-portrait { width: 100px; height: 100px; border-radius: 50%; }
.imgdiv { float: left; }
.close-btn { cursor: pointer; }
.img-item-upload { display: none; }
.license { max-width: 200px;max-height: 200px; }
.displaynone { display: none; }
.order-item span { display: inline-block;width: 300px; }
.attr-box { margin-left: 50px; }
.order-box img {
    max-width: 100%;
}
.dateitem {
    width: 100px;
    background: rgba(236,214,228,0.99);
    display: inline-block;
    margin: 5px;
    text-align: center;
    border-radius: 4px;
    line-height: 20px;
}
.order-block {
    width: 100%;
    height: auto;
    overflow: hidden;
}
