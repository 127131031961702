.exp {
    color: rgba(173,186,198,0.99);
    display: flex;
}
.close-btn { display: inline-block;margin-left:-15px; cursor: pointer; position: absolute; transform: rotate(0deg); transition: transform .5s; }
.close-btn:hover { background: orange; color: white; transform: rotate(180deg); transition: transform .5s; }
.imgDiv { float: left; width: 200px; height: 200px; border: 1px solid #ccc; text-align: center; line-height: 200px; border-radius: 6px; }
.imgBox { float: left; padding: 6px; }
.imgDiv img {
    max-width: 182px;
    max-height: 182px;
}
.moveTo { cursor: move; }
.borderRed { border: 1px solid red; }
.borderGreen { border: 1px solid green; }
.process { width: 200px; float: left; margin: 0 0px 0px 6px; }
